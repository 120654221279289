import React, {useState} from "react";
import './Cursos.css';
import {  Card, CardGroup } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import gpgrey1 from '../img/IMG_4662.JPG';
import gpgrey2 from '../img/IMG_4658.JPG';
import foto1 from '../img/IMG_8580.CR3.jpg';
import foto2 from '../img/IMG_8554.CR3.jpg';
import foto3 from '../img/IMG_8544.CR3.jpg';
import foto4 from '../img/IMG_8517.CR3 (1).jpg';
import foto5 from '../img/IMG_8511.CR3 (1).jpg';
import foto6 from '../img/IMG_1718 (3).jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Carrossel from "../components/Carrossel";
import useWindowSize from "../components/useWindowSize";
import Carrosselmob from "../components/Carrosselmob";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Cursos(){
    const [mostrarMais, setMostrarMais] = useState(false);

    const toggleMostrarMais = () => {
        setMostrarMais(!mostrarMais);
    };

    const { width } = useWindowSize();

    const MySwal = withReactContent(Swal);

    const handleMatriculaClick = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger"
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: "Já fez o curso Base?",
            text: "É necessário ter concluído o curso Base antes de iniciar o Nefesh.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Não",
            reverseButtons: true,
            customClass: {
                popup: "custom-popup",
                title: "custom-title",
                htmlContainer: "custom-content",
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
                actions: "custom-actions" /* Classe para o contêiner dos botões */

            }
        }).then((result) => {
            if (result.isConfirmed) {
                // Redirecionar para o WhatsApp
                window.open("https://wa.me/559191545410", "_blank");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons.fire({
                    title: "Sem problemas!",
                    text: "Você ainda pode se preparar para o Nefesh.",
                    icon: "success",
                    customClass: {
                        popup: "custom-popup-cancel",
                        title: "custom-title-cancel",
                        htmlContainer: "custom-content-cancel",
                        confirmButton: "custom-confirm-button-cancel"
                    }
                });
            }
        });
    };

    return(
        <>
            <NavBar />

            <div className="curso-content">
                <div className="curso-header">
                    <h1 className="curso-title">Curso Base</h1>
                    <a class="btn btn-primary btn-matricula1" href="https://tiketo.com.br/evento/2262" role="button" target="_blank" rel="noopener noreferrer">Matricule-se</a>
        
                </div>
                <p className="curso-description">
                    O curso base trabalha desde o primeiro até o terceiro módulo temas que irão impulsionar sua vida,
                    mudar completamente sua perspectiva cristã e te dar uma base de conhecimento de crescimento e
                    maturidade espiritual.
                </p>
                <p className="curso-description">
                    Aqui você irá aprender ouvir a voz de Deus e desenvolver um relacionamento íntimo e profundo com o
                    Espírito Santo. A base que você precisa para levar uma vida cristã saudável está aqui. Venha conhecer
                    mais do seu criador!
                </p>
            </div>
            <div className="footer-line-curso">
                <div className="content-modulo">
                <h1 className="titulo-curso">Módulos:</h1>
                <CardGroup className="modulos-group">
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">1</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Relacionamento com Deus</li>
                                    <li>O Pecado</li>
                                    <li>Quebrantamento e Arrependimento</li>
                                    <li>Obediência e Autoridade</li>
                                    <li>Santidade</li>
                                    <li>Orgulho X Humildade</li>
                                    <li>Vivendo na Verdade</li>
                                    <li>O Pode da Cruz</li>
                                    <li>Sobrenatural de Deus</li>
                                    <li>O Coração Paterno de Deus</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">2</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>A Raiz da Dor</li>
                                    <li>Perfis Espirituais (parte 1)</li>
                                    <li>Perfis Espirituais (parte 2)</li>
                                    <li>Economia do Reino</li>
                                    <li>Atributos de Deus</li>
                                    <li>Auto Imagem e Valor Do Homem</li>
                                    <li>Família e Base da Sociedade</li>
                                    <li>Vencendo a Luxúria</li>
                                    <li>Ciclo da Liberdade</li>
                                    <li>Estações de Bênçãos (aula prática)</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">3</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Relacionamento com Deus</li>
                                    <li>O Pecado</li>
                                    <li>Quebrantamento e Arrependimento</li>
                                    <li>Cosmovisão Bíblica</li>
                                    <li>Esferas Sociais</li>
                                    <li>Chamado e Propósito de Vida</li>
                                    <li>Os 5 Ministérios</li>
                                    <li>Dons e Manifestações Divinas</li>
                                    <li>Renunciando Meus Direitos</li>
                                    <li>Serviço a Deus</li>
                                    <li>Lealdade</li>
                                    <li>Liderança e Discipulado</li>
                                    <li>O Reino de Deus</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
                <div className="matricula-button-container">
                </div>
                </div>
            </div>
            <div className="curso-content">
                <div className="curso-header">
                    <h1 className="curso-title">Curso Nefesh</h1>
                    <button 
                        className="btn btn-primary btn-matricula1" 
                        onClick={handleMatriculaClick}>
                        Matricule-se
                    </button>

                </div>
                <p className="curso-description">
                NEFESH em hebraico significa ALMA, e essa palavra aparece mais de 700 vezes no Antigo Testamento, sendo usada muitas vezes no lugar da palavra pessoa. Algo como “Existem 33 nefesh na minha família”, ou seja, “seres viventes ou vidas”. Isso nos indica que a nossa alma é centro de VIDA e por isso devemos dar atenção e cuidado. Nossa NEFESH deve ser viva e ter sede de Deus assim como nosso Espírito.
                </p>
                
                 {mostrarMais && (
                <>
                    <p className="curso-description">
                        A escola NEFESH traz luz e verdade sobre áreas ocultas das nossas vidas, traumas, abandonos, rejeições, abusos e outras coisas que gastamos muita energia e sofrimento tentando esconder, mas que Jesus quer curar, ressignificar as suas lembranças ruins.
                    </p>

                    <p className="curso-description">
                        ATENÇÃO: Para cursar o Nefesh você deve cursar o Base antes. O curso base trabalha desde o primeiro até o terceiro módulo temas que irão impulsionar sua vida, mudar completamente sua perspectiva cristã e te dar uma base de conhecimento de crescimento e maturidade espiritual.
                    </p>

                    <p className="curso-description">
                        Aqui você irá aprender ouvir a voz de Deus e desenvolver um relacionamento íntimo e profundo com o Espírito Santo. A base que você precisa para levar uma vida cristã saudável está aqui. Venha conhecer mais do seu criador!
                    </p>
                </>
            )}

            {/* Botão de Mostrar mais ou menos */}
            <button className="btn-mostrar-mais" onClick={toggleMostrarMais}>
                {mostrarMais ? 'Mostrar menos' : 'Mostrar mais'}
            </button>

            {width > 1050 ? <Carrossel /> : <Carrosselmob />} 


            </div>
            <div className="footer-line-nefeshcc">
                <div className="content-nefesh">
                    <h1 className="titulo-nefesh2">Metodologia</h1>
                    <p className="nefesh-description6">
                    Com três meses de duração e dois encontros semanais, a NEFESH inclui atendimentos individuais aos alunos e também grupos de compartilhamento chamados grupos grey, onde também recebemos cura ao ouvirmos os colegas e ao falarmos sobre o toque de Deus a cada aula.
                    </p>
                    <div className="headgp">
                        <img src={gpgrey1} className="grey1"/>
                        <img src={gpgrey2} className="grey2"/>
                    </div>
                    <p className="nefesh-description6">
                    O maior objetivo dessa escola é ressignificar e buscar cura para as dores e traumas pelas quais você passou, levando você à sua forma original, como foi criado por Deus, com uma alma leve, livre e plena. Portanto, esteja com o coração humilde e ensinável, lembrando sempre que tudo que a gente viu e recebeu de Deus até hoje ainda não é tudo o que Ele tem para você.                    
                    </p>
                    <p className="nefesh-rodape">
                    Lourenço e Márcia Ledo - Pastores Conselheiros da Link Church 
                    <br></br>
                    Coordenadores do Curso Nefesh - Aconselhamento, Cura e Libertação
                    </p> 
                </div>
            </div>
            <div className="footer-line-curso">
                <div className="content-modulo">
                <h1 className="titulo-curso">Módulos:</h1>
                <CardGroup className="modulos-group1">
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">1</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                <li>A visão de Amós</li>
                                    <li>Muro da rejeição</li>
                                    <li>Muro da rebelião</li>
                                    <li>Engano e falsos profetas</li>
                                    <li>Os trens da vida</li>
                                    <li>Muro da salvação</li>
                                    <li>O ciclo da liberdade</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">2</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                <li>Introdução a libertação</li>
                                    <li>Consciência espiritua</li>
                                    <li>Sociedade Zumbi</li>
                                    <li>Perfil espiritual de Absalão</li>
                                    <li>Perfil espiritual de Jezabel</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">3</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                <li>A comunicação e os princípios bíblicos</li>
                                    <li>Administrando conflitos</li>
                                    <li>A raiva nos relacionamentos</li>
                                    <li>Temperamentos</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">4</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>As emoções fundamentais</li>
                                    <li>Lidando com o medo</li>
                                    <li>O processo Emanuel</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="modulo-card">
                        <Card.Body>
                            <Card.Title className="modulo-number">5</Card.Title>
                            <Card.Text>
                                <ul className="modulo-text">
                                    <li>Abusos</li>
                                    <li>Vícios e suas raízes</li>
                                    <li>Família funcional e disfuncional</li>
                                    <li>As consequeências da disfuncionalidade </li>
                                    <li>Ferramentas de aconselhamento</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
                </div>
            </div>
            <div className="curso-content">
                <div className="curso-header">
                    <h1 className="curso-title">Curso Rockets</h1>

                </div>
                <p className="curso-description">
                Nosso treinamento especial para líderes e futuros líderes está de cara nova. Estamos muito animados para compartilhar e viver esse tempo de imersão nessa área com vocês! Reformulamos todo o processo, fluxo e didática para sermos intencionais e entregar um conteúdo pautado nos princípios bíblico da liderança de Jesus.
                </p>
                <p className="curso-description">
                O curso será para líderes, futuros líderes da igreja e indicados pela liderança para exercer influência na igreja e em todas as esferas da sociedade. A inscrição será feita por meio de um formulário e após aprovação nossa equipe entrará em contato pelo seu whatsapp para concluir o processo.
                </p>
            </div>
            <div className="footer-line-cc"></div>
            <div className="curso-content">
                <div className="curso-header">
                    <h1 className="curso-title">Cursos Shorts</h1>

                </div>
                <p className="curso-description">
                Os shorts são nossos novos cursos intensivos onde nos aprofundaremos em temas específicos de forma intencional, profunda e intensa com base na palavra de Deus!
                </p>
                <div className="curso-header12">
                    <h2 className="curso-subtitle">Shorts Família</h2>
                    <a class="btn btn-primary btn-matricula1" href="#" role="button" >Matricule-se</a>

                </div>
                <p className="curso-description">
                Nosso objetivo no curso é passar uma visão de famílias funcionais dentro da ótica de reino trazendo cura para lares e ajudando as futuras famílias a colocarem seus  alicerces no Senhor.
                A dinâmica do short família conta com as aulas,  grupos grey (compartilhamento) e aconselhamentos com professores da escola.
                </p>
                <div className="curso-header12">
                    <h2 className="curso-subtitle">Shorts Sobrenatural</h2>
                    <a class="btn btn-primary btn-matricula12" href="http://tiketo.com.br/evento/2430" role="button" target="_blank" rel="noopener noreferrer">Matricule-se</a>

                </div>
                <p className="curso-description">
                o Shorts Sobrenatural é para quem quer aprender a se mover no profético de maneira saudável e bíblica, se você deseja se aprofundar mais no tema esse é próximo passo para você.
                Durante o curso compartilharemos a parte teórica, mas também prática para que você saia dos shorts com esse conhecimento de como colocar o que foi ensinado no seu dia a dia de acordo com os princípios e ensinamentos de Deus.
                </p>
            </div>
            <div className="footer-line-nefeshcc">
                <div className="content-nefesh">
                    <h1 className="titulo-nefesh2">Pra quem é?</h1>
                    <ul className="pq-text">
                        <li>Para você que quer aprender o modelo familiar do Senhor</li>
                        <li>Solteiros que buscam aprender a se relacionar de forma saudável</li>
                        <li>Futuros casais para entrar no casamento com bases</li>
                        <li>Casais que querem fortalecer seu casamento</li>
                    </ul>
                </div>
            </div>
            <div className="footer-line2">
                <div className="contact-info22">
                    <span className="ctt233">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">  
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon1"></i>
                            <span className="ctt-ctt1">9191545410</span>
                        </a>
                        </div>
                        <div className="contact-item">
                        <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon1"></i>
                            <span className="ctt-ctt1">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cursos;
