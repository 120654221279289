import React from "react";
import './Professores.css';
import { Card, CardGroup, Carousel } from 'react-bootstrap';
import prof1 from '../img/IMG_2572.jpg';
import prof2 from '../img/IMG_7953.jpg';
import prof3 from '../img/IMG_1754.jpg';
import prof4 from '../img/IMG_6382.jpg';
import prof5 from '../img/IMG_6936.jpg';
import prof6 from '../img/Cópia de IMG_8455.jpg';
import prof7 from '../img/IMG_6280.jpg';
import prof8 from '../img/Captura de Tela 2025-01-30 às 12.00.15.png';
import prof9 from '../img/IMG_2768.jpg';
import prof10 from '../img/Josué-LinkSchool.jpg';
import prof11 from '../img/Keisse-LinkSchool.jpg';
import prof12 from '../img/IMG_3484.JPG';
import prof13 from '../img/IMG_3486.JPG';
import prof14 from '../img/AQN_0776.png';
import prof15 from '../img/piu.jpg';
import prof16 from '../img/MNG01287.jpg';
import prof17 from '../img/IMG_4668.jpg';
import NavBar from '../components/NavBar';

function Professores() {
    const professores = [
        { nome: 'Pr. Vitor Ledo', foto: prof1 },
        { nome: 'Pra. Mayra Ledo', foto: prof2 },
        { nome: 'Pr. Lourenço Ledo', foto: prof3 },
        { nome: 'Pra. Márcia Ledo', foto: prof4 },
        { nome: 'Pr. Lucas Urrutty', foto: prof5 },
        { nome: 'Pra. Verena Urrutty', foto: prof6 },
        { nome: 'Pr. Renan Amaral', foto: prof7 },
        { nome: 'Lorena Amaral', foto: prof8 },
        { nome: 'Ingrid Souza', foto: prof9 },
        { nome: 'Pr. Josué Silva', foto: prof10 },
        { nome: 'Pra. Keisse Silva', foto: prof11 },
        { nome: 'Maira Viveiros', foto: prof12 },
        { nome: 'Marco Souza', foto: prof13 },
        { nome: 'Raylson Ramiro', foto: prof14 },
        { nome: 'Jessiane Ayala', foto: prof15 },
        { nome: 'Meg Ramos', foto: prof16 },
        { nome: 'Silas Santiago', foto: prof17 },
    ];

    return (
        <>
            <NavBar />
            <div className="curso-content4">
                <div className="curso-header4">
                    <h1 className="curso-title4">Conheça nossos professores</h1>
                </div>
                <p className="curso-description4">
                    Líderes e convidados capacitados e treinados para compartilhar revelações, vida de Deus e jornada com você durante nossos cursos
                </p>
            </div>
            <div className="footer-line-curso4">
                <div className="content-modulo4">
                    <CardGroup className="professor-card-group">
                        {professores.map((prof, index) => (
                            <Card key={index} className="professor-card">
                                <Card.Body>
                                    <Card.Title className="professor-name">{prof.nome}</Card.Title>
                                    <Card.Img 
                                        variant="bottom" 
                                        className={`professor-img ${prof.nome === 'Lorena Amaral' ? 'lorena-img' : ''}`} 
                                        src={prof.foto} 
                                    />
                                </Card.Body>
                            </Card>
                        ))}
                    </CardGroup>
                    <Carousel className="carousel-professores mobile-only">
                        {professores.map((prof, index) => (
                            <Carousel.Item key={index}>
                                <Card className="professor-card">
                                    <Card.Body>
                                        <Card.Title className="professor-name">{prof.nome}</Card.Title>
                                        <Card.Img 
                                            variant="bottom" 
                                            className={`professor-img ${prof.nome === 'Lorena Amaral' ? 'lorena-img' : ''}`} 
                                            src={prof.foto} 
                                        />
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className="footer-line31">
                <div className="contact-info2">
                    <span className="ctt24">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item">
                            <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-whatsapp contact-icon"></i>
                                <span className="ctt-ctt">9191545410</span>
                            </a>
                        </div>
                        <div className="contact-item">
                            <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-instagram contact-icon"></i>
                                <span className="ctt-ctt">linkschoolbr</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Professores;