import React,{ useState }  from "react";
import './home.css';
import NavBar from '../components/NavBar';
import { Dropdown, Card, CardGroup } from 'react-bootstrap';
import foto1 from '../img/ls.png';
import foto2 from '../img/1b377c5b903ff396e38b7ffe7963c608.png';


function Home() {
    const [show, setShow] = useState(false);

    const toggleDropdown = () => {
        setShow((prevShow) => !prevShow);
    };
    return (
        <>
            <NavBar />
            <div className="content">
                <div className="content-row">
                    <div className="text-content">
                        <h1 className="titleH">Conheça a Link School</h1>
                        <img src={foto1} className="side-image mobile-only" alt="Descrição da imagem" />
                        <p className="descriptionH">
                            A Link School é o braço de ensino e formação ministerial da Link Church. 
                            <br></br>
                            Somos 100% cristocêntricos, interdenominacionais e avivados. Aqui você aprenderá princípios e fundamentos da fé cristã de uma maneira única e transformadora.
                        </p>
                        <div className="line"></div>
                        <Dropdown className="mt-3" show={show} onToggle={toggleDropdown} autoClose="outside">
                        <Dropdown.Toggle variant="primary" size="lg" className="titulob" onClick={toggleDropdown}>
                            Matricule-se
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-horizontal">
                            <Dropdown.Item href="/cursobase">
                                <span className="light">Curso </span><span className="bold">Base</span>
                            </Dropdown.Item>
                            <span className="separator">|</span>
                            <Dropdown.Item href="/cursonefesh">
                                <span className="light">Curso </span><span className="bold">Nefesh</span>
                            </Dropdown.Item>
                            <span className="separator">|</span>
                            <Dropdown.Item href="/cursoshorts">
                                <span className="light">Link School </span><span className="bold">Shorts</span>
                            </Dropdown.Item>
                            <span className="separator">|</span>
                            <Dropdown.Item href="/cursorockets">
                                <span className="light">Curso </span><span className="bold">Rockets</span>
                            </Dropdown.Item>
                            
                        </Dropdown.Menu>
                    </Dropdown>


                    </div>
                    <img src={foto1} className="side-image desktop-only" alt="Descrição da imagem" />
                </div>
            </div>
            <div className="footer-line"></div>
            <div className="fundo-video">     
                <div className="contet-video">
                    <div className="text-video">
                        <h1 className="tituloV">Venha ter sua vida transformada nas nossas salas de aula.</h1>
                       
                        <p className="descricaoV">Nossa escola é voltada para aqueles que desejam ir mais fundo no seu relacionamento com Deus para viver uma vida relevante na terra com propósitos forjados nos céus.</p>
                    </div>
                    
                </div>
            </div>
            <div className="somente-video">
            <video width="2090" height="815" controls autoPlay>
                    <source src="/videos/Link School RVL (1).mp4" type="video/mp4" />
                    Seu navegador não suporta a tag de vídeo.
                </video>
            </div>
            <div className="footer-line"></div>
            <div className="fundo-video">
            <section id="quem-somos">
                <div className="contet-video">
                
                    <div className="text-video">
                        
                        <h1 className="tituloV2">A Link School</h1>
                        <p className="descricaoV2">A LS nasceu de um desejo profundo em formar cristãos não só apaixonados por Jesus mas que consigam viver uma vida de acordo com a fé que professam. Acreditamos que quando alguém tem um encontro verdadeiro com Deus, seu caráter, seus princípios e suas opiniões são mudados. 
                        <br /><br />
                        Acreditamos que existem áreas de nossas vidas que precisam de luz  para que cresçamos espiritualmente maduros e libertos de tudo aquilo que ainda nos afasta de viver a nossa verdadeira identidade de filho.
                        <br /><br />
                        Nosso objetivo é que você tenha revelação da sua identidade, paternidade e propósito específico que Deus tem para sua vida!</p>
                    </div>
                    <img src={foto2} className="imagemls"/>
                    
                </div>
                </section>
            </div>
            <div className="alo">
                <h1 className="titulo-card">3 Motivos para você fazer a Link School</h1>
                <CardGroup>
                    <Card className="card-motivo">
                        <Card.Body>
                            <Card.Title className="card-number">1</Card.Title>
                            <Card.Text>Sua fé precisa de fundamento, base e alicerce para você não ser levado por vento de doutrina</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-motivo">
                        <Card.Body>
                            <Card.Title className="card-number">2</Card.Title>
                            <Card.Text>Construir um relacionamento íntimo e sobrenatural com Deus</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="card-motivo">
                        <Card.Body>
                            <Card.Title className="card-number">3</Card.Title>
                            <Card.Text>Entender a paternidade de Deus, sua identidade e propósito</Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </div>
            <div className="fundo-video">
                <div className="contet-depo">
                    <h1 className="tituloD">Depoimentos</h1>
                    <div className="depo-group">
                        <Card className="depo-card2">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                    “Foi um divisor águas no meu relacionamento com Jesus. Através da escola eu pude experimentar um nível mais profundo de intimidade com o meu Senhor”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Letícia Cardoso</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “Participar do curso NEFESH sobre a cura da alma foi incrível. Desde a primeira
                                aula, Deus touxe revelações profundas sobe cada trauma e dor que existiam
                                dentro de mim, muitos dos quais eu nem sabia que existiam. O curso me guiou
                                através de um processo de cura profunda, trazendo renovação para minha fé e
                                relacionamento com Deus. Finalizo o curso com muita gratidão aos Pastores
                                Lourenço e Márcia Ledo, por toda dedicação, por todo cuicado e amor, sendo
                                incansáveis nesse processo.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Lohohanne Paraíso</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                "Após 17 anos de convertido, o resumo dos últimos 3 meses, após a Nefesh... Eu
                                tinha um temor humano e Deus me deu um temor espiritual, eu não tinha
                                identidade e ele me mostrou quem eu sou Nele, eu estava na superfície e ele está
                                me levando mais fundo.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Fábio Dias </small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="depo-group">
                        <Card className="depo-card2">
                            <Card.Body>
                                <Card.Text className="text-depo"> 
                                “Foi um processo de descobertas em Deus, meito confrontos, porém com cura e
                                revelação.. A palavra que define todo esse processo é auto-responsobilidade para
                                cominhar a Jornado de cura e transformação em Deus.”

                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Andrieli Caroline</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card2">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “A Nefesh para mim foi um divisor de águas em minha vida, foi onde eu identifiquei
                                dores, traumas e feridas escondidas em minha alma.
                                Cada aula, atendimento, compartilhamentos e orações eu pude descobrin as
                                raízes daqueles problemas e o quanto eu precisava enfrenta-los com a Verdade
                                que Deus tinha sobre mim. Foi um tempo de cura da alma e libertação para viver a
                                plenitude do que Deus tem reservado pare mim!”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Lucas ParaÍso</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “A Nefesh representou o ponto de partida, onde permiti que Deus trouxesse
                                verdade e luz em áreas da minha vida onde existia um grande engano.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Thomaz Araújo</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “A Nefesh foi uma grande oportunidade dada por Deus para viver um tempo
                                incrível de submissão aos Espírito Santo e ser vulnerável para transformação da
                                mente e cura da alma.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Letícia Oliveira</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="depo-group">
                        <Card className="depo-card2">
                            <Card.Body>
                                <Card.Text className="text-depo"> 
                                “A Nefesh foi em tempo de ir mais fundo! Ir em lugares, por mais dolorosos que
                                fossem, para que a cura pudese vir à tona. A Nefeh foi o aprofundamento de um
                                processo que inciei e que continuará. Para ser sincera, não foi fácil, lembro que
                                quando chegou a 1º aula que não chorei (a 14°), pensei: " é, a cura tá vindo KKK".
                                Mas também fui sentindo e tenho sentido paz e alívio por saber que tenho vivido no
                                centro da vontade de Deus. A Nefesh foi um tempo de crescimento em Cristo.
                                Nossa fé tem sido esticada. Foi uma verdadeira chave, na qual abracei 100% meu
                                processo, decidi verdadeiramente viver a vontade de Deus para minha vida.
                                Intencionalmente, busquei ir às raizes da dor. Passei a persegui a cura e buscar
                                encontrar Jesus nas memórias dolorosas. Ao fim deste tempo, uma paz me toma,
                                tenho estado feliz e segura na pessoa onde estabelecerei raizes, que é Cristo.”
                                </Card.Text>
                                <Card.Footer>
                                <small className="text-muted">Caroline Lavaredo</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card2">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “O Curso Nefesh representa meu "próximo nível"; há alguns anos tenho caminhado
                                na seguinte frase 'conhecer Jesus e faze-lo conhecido"
                                Assim, entendo que todos os processos de cura e libertação foram necessários
                                para que tudo que em dia recebi, eu possa entregar.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Maria Eduarda Souza</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “A Nefesh é uma escola transformadora. Por tudo que tenho vivido, só tenho a
                                agradecer a liderança, eu entrei uma pessoa e sai uma filha. Só o papai sabe
                                quanto fui transformada. Aprendi coisas que nem sonhei em aprender nesses 20
                                anos andando com Jesus. Avancei demais individualmente estes meses e espero
                                avançar com o reino a partir de agora...”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Suzanny Dias</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                        <Card className="depo-card3">
                            <Card.Body>
                                <Card.Text className="text-depo">
                                “A Nefesh foi uma grande oportunidade dada por Deus para viver um tempo
                                incrível de submissão aos Espírito Santo e ser vulnerável para transformação da
                                mente e cura da alma.”
                                </Card.Text>
                                <Card.Footer>
                                    <small className="text-muted">Letícia Oliveira</small>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="footer-line22">
                <div className="contact-info">
                    <span className="ctt">CONTATO</span>
                    <div className="contact-details">
                        <div className="contact-item"> 
                        <a href="https://wa.me/559191545410" target="_blank" rel="noopener noreferrer"> 
                            <i className="bi bi-whatsapp contact-icon"></i>
                            <span className="ctt-ctt">9191545410</span>
                        </a>
                        </div>
                        <div className="contact-item">
                            <a href="https://www.instagram.com/linkschoolbr/" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram contact-icon"></i>
                            <span className="ctt-ctt">linkschoolbr</span>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
